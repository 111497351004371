/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        this.doSearch();

        this.ppClose();

        this.screenRotation();

        var triggerWishlist = this.doWishlist;
        triggerWishlist();

        this.initialAjax();

        var redrowSVG = this.doSVG;
        redrowSVG();

        var functionLoadPosts = this.loadPosts;

        $body = $('body');

        $body.on( 'redrowSVG', function(){
          redrowSVG();
        });

        $body.on( 'doWishlist', function(){
          triggerWishlist();
        });

        //load more
        $loadMore = $('.js-load-more');

        totalProducts = $('[data-initial-posts]').data('initial-posts');
        var $displyedProducts = $('.product-wrap');

        if ( totalProducts <= $displyedProducts.length ) {
          $loadMore.hide();
        }

        $loadMore.on( 'click', function() {
          if ( $loadMore.hasClass('btn-disabled') ) {
            return;
          }
          $loadButton = $(this);
          $loadButton.hide();
          var $loadMoreWrap = $loadButton.closest( '.list-loader' );
          var $spinner = $loadMoreWrap.find( '.spinner' );
          $spinner.show();

          functionLoadPosts().done( function(data){
            data = JSON.parse(data);
            // console.log( data.debug );
            if ( data.status === 'success' ) {
              $productsWrap = $('.products-list-wrap');
              $productsWrap.find('.container').append( data.products );
              $spinner.hide();
              $loadButton.show();
              if ( data.remaining === 0 ) {
                $loadMore.addClass('btn-disabled');
              }
            }
            redrowSVG();
          });
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
      ppClose: function() {
        var $body = $('body');
        $body.on( 'classUpdate', function(){
          setTimeout( function() {
            if ( $body.hasClass( 'svi-popup-active' ) ) {
              // $body.scrollTop( 0 );
              var $ppClose = $('.pp_close');
              $ppClose.click( function(){
                $body.removeClass('svi-popup-active');
              });

              $(document).keyup(function(e) {
                if (e.keyCode == 27) { // escape key maps to keycode `27`
                      $body.removeClass('svi-popup-active');
                  }
              });

              $ppContainer = $( '.pp_hoverContainer' );

              $ppContainer.on( 'swipeleft', function( event ) {
                $ppNext = $( '.pp_next' );
                $ppNext.trigger( 'click' );
              });

              $ppContainer.on( 'swiperight', function( event ) {
                $ppRight = $( '.pp_previous' );
                $ppRight.trigger( 'click' );
              });

            }
          }, 40 );
        });
      },
      doSVG: function() {
        $('img.svg').each(function(){
            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            $.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = $(data).find('svg');

                // Add replaced image's ID to the new SVG
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });
      },
      initialAjax: function() {
          // dom loaded init ajax
          $body = $('body');

          /* handle data retrieved at page init */
          if ( initAjax['success'] === 1 ) {
            /* data already in the variable */
            if ( typeof initAjax['cart_count'] !== 'undefined' ) {
              if ( initAjax['cart_count'] > 0 ) {
                $cartLink = $('.cart-link');
                $cartLink.append( '<span class="cart-has-items nav-has-number"><span class="items-count">' + initAjax['cart_count'] + '</span></span>' );
              }
            }
            if ( typeof initAjax['wishlist_count'] !== 'undefined' ) {
              var count = initAjax['wishlist_count'];
              var $wishCount = $( '.js-wishlist-count' );
              $wishCount.text( count );
              if ( count > 0 ) {
                  $wishCount.parent('.heart-has-items').removeClass('heart-no-items');
              }
            }
          } else {
            $body.on('initAjaxSuccess', function() {
              if ( typeof initAjax['cart_count'] !== 'undefined' ) {
                if ( initAjax['cart_count'] > 0 ) {
                  $cartLink = $('.cart-link');
                  $cartLink.append( '<span class="cart-has-items nav-has-number"><span class="items-count">' + initAjax['cart_count'] + '</span></span>' );
                }
              }
              if ( typeof initAjax['wishlist_count'] !== 'undefined' ) {
                var count = initAjax['wishlist_count'];
                var $wishCount = $( '.js-wishlist-count' );
                $wishCount.text( count );
                if ( count > 0 ) {
                    $wishCount.parent('.heart-has-items').removeClass('heart-no-items');
                }
              }
            });
          }

          var ajaxAction = [];
          /* Same Colection */
          var $sameCollection = $('.same-collection');
          if ( $sameCollection.length >= 1 ) {
              var $productsRow = $sameCollection.find('.same-collection-row');
              if ( $sameCollection.length ) {
                  currentId = $sameCollection.find('.same-collection-row').data('current-id');
                  var data = {
                      'currentId' : currentId
                  };
                  ajaxAction.push( {sameCollection: data } );
              }
          }
          /* END Same Colection */
          /* Wishlist check */
          var $productsList = $('.products-list-wrap');
          if ( $productsList.length >= 1 ) {
              var $btnLiked = $productsList.find( '.like-action' );
              var artworkIds = [];
              $.each( $btnLiked, function( key, value ) {
                  artworkIds.push( $( value ).data('prod_id') );
              });
              ajaxAction.push( { productList: artworkIds } );
          }
          /* END Wishlist check */
          /* RUN INIT AJAX */
          $.post(
              ajaxurl,
              {
                  'action': 'init_theme_ajax', // localted at lib/functions.php
                  'data': ajaxAction
              },
              function( response ) {
                  response = $.parseJSON( response );
                  if ( typeof response['debug'] !== 'undefined' ) {
                      // console.log( response['debug'] );
                  }
                  if ( response['status'] === 'success' ) {
                      if ( $sameCollection.length >= 1 ) {
                        if ( typeof response['related_products'] !== 'undefined' ) {
                          $productsRow.removeClass('loading-products');
                          if ( response['related_products'] != 0 ) {
                            $productsRow.append( response['related_products'] );
                          } else {
                            $sameCollection.hide();
                          }
                        } else {
                            $sameCollection.hide();
                        }
                      }
                      /* Wishlist check */
                      if ( typeof response['wishlisted_products'] !== 'undefined' ) {
                          var likedIds = response['wishlisted_products'];
                          $.each( $btnLiked, function( key, value ) {
                              if ( likedIds.indexOf( String( $(value).data('prod_id') ) ) > -1 ) {
                                  $img = $( value ).find( '.wishlist-icon' );
                                  $img.attr( 'src', '/wp-content/themes/atl/dist/images/heart-full.svg' );
                                  $img.attr( 'onerror', "this.onerror=null; this.src='/wp-content/themes/atl/dist/images/heart-full.png'");
                                  $img.addClass( 'wishlist-icon-wishlisted' );
                              }
                          });
                      }
                      $body.trigger( 'redrowSVG' );
                      /* END Wishlist check */
                  } else {
                      // console.log( 'something went wrong' );
                      return;
                  }
              }
          );
          /* END RUN INIT AJAX */
      },
      doWishlist: function() {
        var $wishCount = $( '.js-wishlist-count' );
        var $productsList = $('.products-list-wrap');
        var runWishlist = function() {
          $likeButtons = $('.like-action');
          $likeButtons.each( function( key, button ) {
            $button = $(button);
            $button.click( function() {
              wishCountNo = parseInt( $wishCount.text() );
              var currentProduct = $(this);

              var product_id = currentProduct.data('prod_id');
              if ( currentProduct.find('.wishlist-icon').hasClass('wishlist-icon-wishlisted') ) {
                currentProduct.find('.wishlist-icon').removeClass('wishlist-icon-wishlisted');
                currentProduct.find('.wishlist-icon').addClass('wishlist-icon-not-wishlisted');
                wishCountNo = wishCountNo - 1;
                if ( wishCountNo == 0 ) {
                  $wishCount.parent('.heart-has-items').addClass( 'heart-no-items' );
                }
                $wishCount.text( wishCountNo );
                currentProduct.find('.wishlist-icon').prop('src', themeDir + '/dist/images/heart.svg');
                currentProduct.find('.wishlist-icon').prop('onerror', "this.onerror=null; this.src='" + themeDir + "/dist/images/heart.png'");
              } else {
                currentProduct.find('.wishlist-icon').removeClass('wishlist-icon-not-wishlisted');
                currentProduct.find('.wishlist-icon').addClass('wishlist-icon-wishlisted');
                wishCountNo = wishCountNo + 1;
                $heartNumberBackground = $wishCount.parent('.heart-has-items');
                if ( $heartNumberBackground.hasClass('heart-no-items') ) {
                  $heartNumberBackground.removeClass('heart-no-items');
                }
                $wishCount.text( wishCountNo );
                currentProduct.find('.wishlist-icon').prop('src', themeDir + '/dist/images/heart-full.svg');
                currentProduct.find('.wishlist-icon').prop('onerror', "this.onerror=null; this.src='" + themeDir + "/dist/images/heart-full.png'");
              }

              var data = {
                'productId': product_id
              };

              $.post(
                ajaxurl,
                {
                  'action': 'wishlist_product', // localted at lib/woocommerce-functions.php
                  'data': data
                },
                function( $response ) {
                  if ( $response == 1 ) {
                    // item was added
                  } else {
                    // item was removed
                  }
                }
              );

            });
          });
        };
        $productsList.bind("DOMSubtreeModified",function(){
          $likeButtons = $('.like-action');
          $likeButtons.each( function( key, button ) {
            $button = $(button);
            $button.unbind('click');
          });
          runWishlist();
        });

        runWishlist();
      },
      doSearch: function() {
        $searchButton = $('.js-show-search');
        $searchButton.on( 'click', function() {
          if ( $('#searchModal').length < 1 ) {
            searchString = "Search"; /* read this from the cms */
            var template = '<div class="modal fade search-modal" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="searchModal" aria-hidden="true"><div class="modal-full"><div class="modal-content"><div class="search-wrap"><div class="close-wrap"><div class="container"><div class="row"><div class="col-md-12"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fa fa-times"></i></span></button></div></div></div></div><div class="container"><div class="row"><div class="col-md-12"><form method="get" action="/' + currentLanguage + '/"><div class="row"><div class="col-sm-9"><input type="text" name="s" class="primary-input search-input" autocomplete="off" placeholder="' + searchInputPlaceholder + '"></div><div class="col-sm-3"><div class="search-submit-wrap"><button type="submit" class="btn btn-boxed btn-vogue search-btn"><i class="fa fa-search fa-3x"></i></button></div></div></div></form></div></div></div></div></div></div></div>';
            $pageFooter = $('.page-footer');
            $.when( $pageFooter.append( template ) ).done( function() {
              $.when( $( '#searchModal' ).modal( 'show' ) ).done( function() {
                setTimeout( function() {
                  $('.search-input').focus();
                }, 200 );
              });
            });
          } else {
            $( '#searchModal' ).modal( 'show' );
          }

        });
      },
      loadPosts: function() {
        // read existing products
        var productsIds = [];
        var $displyedProducts = $('.product-wrap');

        if ( typeof ( searchString ) === 'undefined' ) {
          searchString = '';
        }

        $displyedProducts.each( function( key, product ){
          var $product = $(product);
          productsIds.push( $product.data('product') );
        });

        $productsWrap = $('.products-list-wrap');

        var data = {
          'currentLanguage': currentLanguage,
          'productsIds': productsIds,
          'productCat': $productsWrap.data('filter-product-cat'),
          'productContinent': $productsWrap.data('filter-continent'),
          'productCountry': $productsWrap.data('filter-country'),
          'searchString': searchString
        };

        // make ajax call
        return $.post(
          ajaxurl,
          {
              'action': 'get_more_posts', // localted at lib/woocommerce-functions.php
              'data': data
          }
        );
      },
      screenRotation: function() {
        jQuery( window ).on( "orientationchange", function( event ) {
          if ( $('html').hasClass( 'slb_overlay' ) ) {
            // Save the displayed image in a variable
            var lightboxImageURL = $('.slb_content').find('img').attr('src');
            // close the lightbox
            $( '.slb_template_tag_ui_close' ).click();
            // Click the saved image url link
            setTimeout(function() {
              $('a[href="' + lightboxImageURL + '"]').click();
              console.log( lightboxImageURL );
            }, 1000);
          }
        });
      }
    },
    // woocommerce pages
    'woocommerce_page': {
      init: function() {
          this.productFilters();
          this.colissimo();
      },
      colissimo: function() {
        var $colissimoOption = $('.woocommerce ul#shipping_method li').last();
        if ( $colissimoOption ) {
          $colissimoOption.find('.shipping_method').trigger( 'click' );
        }
      },
      productFilters: function() {
        var $productsFilterWrap = $('.products-filter-wrap');
        /* Reset Filters */
        $reseter = $( $productsFilterWrap.find( '.js-reset-filters' ) );
        $reseter.on( 'click', function() {
          $form = $(this).closest('.products-filter-wrap');
          $inputs = $form.find('.js-filter-option-value');
          $.when(
            $inputs.each( function( key, productFilter ) {
              var $productFilter = $( productFilter );
              $productFilter.val('');
            })
          ).done( function(){
            $productsFilterWrap.submit();
          });
        });
        // Check Reseter
        var checkReset = function() {
          var toShowReset = 0;
          $.when(
            $productsFilterWrap.find( '.js-filter-taxonomy-selected' ).each( function( key, filter) {

              if ( $(filter).hasClass('taxonomy-is-selected') ) {
                toShowReset = 1;
              }
            })
          ).done( function() {
            if ( toShowReset === 1 ) {
              $reseter.show();
            } else {
              $reseter.hide();
            }
          });

        };
        checkReset();
        /* End Reset Filters */
        
        // on filters change
        $( $productsFilterWrap.find( '.js-filter-option' ) ).on( 'click', function() {
          // if the user clickes on the already selected item just ignore the click
          if ( $(this).hasClass('filter-option-selected') ) {
            return;
          }

          // set the corresponding hidden input with the current item value
          $currentFilter = $(this).closest('.js-product-filter-wrap');

          $input = $currentFilter.find('.js-filter-option-value');
          $input.val( $(this).data('value') );

          $productsFilterWrap.submit();
        });

        /* Mobile Show/Hide Filters */
        $filtersWrap = $('.filter-form-wrap');
        $showFilters = $filtersWrap.find( '.show-filters' );
        $hideFilters = $filtersWrap.find( '.hide-filters' );
        $filtersForm = $filtersWrap.find( '.products-filter-wrap' );

        $showFilters.on( 'click', function(){
          $filtersForm.show();
          $(this).hide();
          $hideFilters.show();
        });

        $hideFilters.on( 'click', function() {
          $filtersForm.hide();
          $(this).hide();
          $showFilters.show();
        });
      },
    },
    // Home page
    'home': {
      mainSlider: function() {
        var $sliderWrap = $('.main-slider-wrap');
        $('.main-slider-wrap').slick({
          arrows: false,
          dots: true,
          autoplay: true,
          autoplaySpeed: 3000,
        });
      },
      init: function() {
        // JavaScript to be fired on the home page
        this.mainSlider();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single_product': {
      init: function() {
        // JavaScript to be fired on the single product page
        this.variationsInit();
        this.productSelectionFunc();
        this.variationChanges();
        this.framedChanges();
        this.shareProduct();
        this.translateVariations();
      },
      variationsInit: function() {
        var $variations = $('.variations');
        var $defaultVariation = $variations.find('input').first();
        $defaultVariation.prop("checked", true);
      },
      productSelectionFunc: function() {
        var existingProductFrame = "";
        var existingProductSize = "";

        setTimeout( function() {
          var $variationsForm = $('.variations_form');

          if ( location.search.indexOf( 'attribute_size' ) > -1 ) {

            $variationsForm.on( 'existingCalculated', function() {
              if ( existingProductFrame == "framed" ) {
                setTimeout( function(){
                  var $framedOption = $( '.framed-option' ).find('input').trigger( 'click' );
                }, 80);
              } else {
                var $selectionDetails = $('#user-selection-details');
                var currentSelectionIsFramed = $selectionDetails.find('.js-is-framed');
                currentSelectionIsFramed.hide();
                currentSelectionIsFramed.removeClass( 'pipe-prefix' );
                currentSelectionIsFramed.text( framedString );
              }

              $variationsForm.find('[value="' + existingProductSize + '"]').trigger('click');
            });

            var queryStrings = location.search.split('&');

            $.each( queryStrings, function( key, queryString ) {
              queryString = queryString.replace( '?', '' );
              if ( queryString.indexOf( 'attribute_size' ) > -1 ) {
                var productSizeString = queryString.split( '=' )[1];
                productSizeString = productSizeString.replace( '+', ' ' );
                existingProductSize = productSizeString;
              } else {
                if ( queryString.indexOf( 'frame' ) > -1 ) {
                  var productFrameString = queryString.split( '=' )[1];
                  existingProductFrame = 'framed';
                }
              }

              $variationsForm.trigger( 'existingCalculated' );
            })

          } else {
            var existingProductId = parseInt( sessionStorage.getItem('productSelectionId') );

            var allProductIds = $('.product_title.text-uppercase').data('translation-ids');

            if ( existingProductId !== null ) {
              // we have a selection check if we have the same post id
              if ( allProductIds.indexOf( existingProductId ) > -1 ) {
                var $framedOption = $('.canvas-framed');
                var $selectionDetails = $('#user-selection-details');
                var currentSelectionIsFramed = $selectionDetails.find('.js-is-framed');
                var $thumbsWrap = $('.svithumbnails');

                existingProductSize = sessionStorage.getItem( 'productSelectionSize' );
                existingProductFrame = sessionStorage.getItem( 'productSelectionFrame' );

                if ( existingProductFrame == "framed" ) {
                  setTimeout( function(){
                    var $framedOption = $( '.framed-option' ).find('input').trigger( 'click' );
                  }, 80);
                } else {
                  var $selectionDetails = $('#user-selection-details');
                  var currentSelectionIsFramed = $selectionDetails.find('.js-is-framed');
                  currentSelectionIsFramed.hide();
                  currentSelectionIsFramed.removeClass( 'pipe-prefix' );
                  currentSelectionIsFramed.text( framedString );
                }

                $variationsForm.find('[value="' + existingProductSize + '"]').trigger('click');

              }

            }
          }

        }, 60 );
      },
      variationChanges: function() {
        var $variationsTable = $('.variations');
        var $selectionDetails = $('#user-selection-details');
        var $variationsForm = $('.variations_form');
        var productId = $variationsForm.data( 'product_id' );
        var variationsData = $variationsForm.data('product_variations');

        var $variationsFeedback = $('.variation-details');

        // add radio buttons
        $radioButtons = $variationsTable.find('[type="radio"]');
        setTimeout( function() {
          $.each( $radioButtons, function( key, radioButton ) {
            $(radioButton).siblings( 'label' ).append( '<div class="radio-check"></div>' );
          });
        }, 80 );
        // end of add radio buttons
        // framed checkbox
        $framedOption = $( '.canvas-option-value' );
        setTimeout( function() {
          $framedOption.siblings('label').append( '<div class="radio-check"></div>' );
        }, 80 );
        // end of add radio buttons

        $( $variationsTable.find('input:checked').first() ).parent().addClass( "active-variation" );
        $( $variationsTable.find('input:checked') ).parent().addClass( "variation-wrap" );
        var $activeVariation = $variationsTable.find('input:checked').first();

        $.each( $variationsFeedback, function( key, variationFeedback ) {
          if ( $activeVariation.val() == $( variationFeedback ).data('size') && $( variationFeedback ).data('framed') != 'framed' ) {
            $( variationFeedback ).show();
            $( variationFeedback ).addClass('active-feedback');
          }
        });

        setTimeout( function() {
          var variations = $variationsTable.find('input');
          var $thumbsWrap = $('.svithumbnails');
          variations.each( function( key, variation ) {
              $( variation ).change( function(){
                  var $activeVariation = $variationsTable.find('input:checked');

                  sessionStorage.setItem( 'productSelectionSize', $activeVariation.val() );
                  sessionStorage.setItem( 'productSelectionId', productId );

                  $activeVariation.parent().addClass( "active-variation" );

                  $variationsTable.find('input:not(:checked)').parent().removeClass( "active-variation" );

                  setTimeout( function() {

                    var $framedOption = $('.canvas-framed');

                    var frameAvailable;

                    $.each( $variationsFeedback, function( key, variationFeedback ) {

                      $variationFeedback = $( variationFeedback );

                      if ( $activeVariation.val() == $variationFeedback.data('size') ) {
                        $( variationFeedback ).show();
                        $( variationFeedback ).addClass('active-feedback');

                        var frameFeedback = $variationFeedback.find( '.js-is-framed' );
                        var frameStock = parseInt( frameFeedback.data( 'frame-stock' ) );

                        var canvasOptionLabel = $('.canvas-option-label');
                        if ( frameStock < 1 ) {
                          canvasOptionLabel.addClass( 'option-disabled' );
                          $('.forced-product-stock-status-wrap').show();
                          if ( $framedOption.prop('checked') ) {
                            $( '.framed-option' ).find('input').trigger( 'click' );
                          }
                        } else {
                          canvasOptionLabel.removeClass( 'option-disabled' );
                          $('.forced-product-stock-status-wrap').hide();
                        }
                      } else {
                        $( variationFeedback ).hide();
                        $( variationFeedback ).removeClass('active-feedback');
                      }
                    }).promise().done( function() {
                      if ( $framedOption.prop('checked') ) {
                        var imgChanged = 0;
                        var variationImages = $thumbsWrap.find('img');
                        $.each( variationImages, function( key, img ) {
                          if ( imgChanged === 0 ) {
                            var $img = $(img);
                            var imgFramedData = $img.data( 'sviimg' );
                            if ( imgFramedData['woosvi_framed'] === 'framed' ) {
                              setTimeout( function() {
                                $img.trigger('click');
                              }, 100 );
                              imgChanged = 1;
                            }
                          }
                        });
                        var framed = 'framed';
                      } else {
                        $thumbsWrap.find('.first img').trigger('click');
                        var framed = 'unframed';
                      }
                    });

                  }, 10);

                  setTimeout(function() {
                      /* Update price */
                      var $priceWrap = $('.woocommerce-variation');
                      var $price = $priceWrap.find('.price');
                      var priceText = $price.text().replace(/\d+|^\s+|\s+$/g,'');
                      var priceNumber = $price.text().replace(/\D/g,'');
                      var $activeFeedback = $('.active-feedback');
                      var $feedbackFrame = $activeFeedback.find('.js-is-framed');
                      var framePrice = $feedbackFrame.data('frame-price');
                      var newPrice = parseInt( priceNumber ) + parseInt( framePrice );

                      if ( $feedbackFrame.hasClass('pipe-prefix') ) {
                          newPrice = newPrice + priceText;
                      } else {
                        newPrice = $activeFeedback.data('price') + priceText;
                      }
                      $price.text( newPrice );
                      /* END OF UPDATE PRICE */
                  }, 20 );

                  setTimeout( function() {
                      /* Custom Cart Image */
                      $('#custom-image').val( $('#woosvimain').find('img').data('svizoom-image') );
                      /* END Custom Cart Image */
                  }, 2000 );



              });
          });

          $thumbsWrap.bind( 'DOMSubtreeModified', function() {
            var galleryItems = $thumbsWrap.find('img');
            $.each( galleryItems, function( key, galleryItem ) {
              $( galleryItem ).click( function() {
                $thumbsWrap.find('li').removeClass('active');
                $( galleryItem ).parent().addClass('active');
              });
            });
          });
        }, 20);
      },
      framedChanges: function() {
        var $framedOptionWrap = $('.framed-option');
        var $framedOption = $framedOptionWrap.find('.canvas-framed');
        var $selectionDetails = $('#user-selection-details');
        var currentSelectionIsFramed = $selectionDetails.find('.js-is-framed');
        var $thumbsWrap = $('.svithumbnails');
        var $variationsForm = $('.variations_form');
        var productId = $variationsForm.data( 'product_id' );

        // Trigger change to update the image
        var $variationsTable = $('.variations');
        var $activeVariation = $variationsTable.find('input:checked').first();

        if ( $framedOption.prop('checked') ) {
          currentSelectionIsFramed.text( framedString );
          currentSelectionIsFramed.show();
          currentSelectionIsFramed.addClass( 'pipe-prefix' );
        } else {
          currentSelectionIsFramed.hide();
          currentSelectionIsFramed.removeClass( 'pipe-prefix' );
          currentSelectionIsFramed.text( framedString );
        }

        var productSelectionFrame = '';

        $framedOptionLabel = $framedOption.siblings('label');
        $framedOption.change( function() {
          if ( $framedOption.prop('checked') ) {
            productSelectionFrame = "framed";
            currentSelectionIsFramed.show();
            currentSelectionIsFramed.addClass( 'pipe-prefix' );
            $framedOptionLabel.addClass('framed-checked');
          } else {
            productSelectionFrame = "unframed";
            currentSelectionIsFramed.hide();
            currentSelectionIsFramed.removeClass( 'pipe-prefix' );
            $framedOptionLabel.removeClass('framed-checked');
          }

          $activeVariation.trigger('change');

          sessionStorage.setItem( 'productSelectionFrame', productSelectionFrame );
          sessionStorage.setItem( 'productSelectionId', productId );
        });
      },
      shareProduct: function() {
        $shareButton = $('.js-share-action');
        $shareWrap = $('.social-shares-wrap');
        $shareButton.on( 'click', function(){
            $shareWrap.toggle(400);
        });
      },
      translateVariations: function() {
        var $largeSize = $('[for="size_v_LARGE FORMAT"]');
        if ( !$.isEmptyObject( $largeSize ) ) {
          $largeSize.text( translationLargeSize );
        }

        var $mediumSize = $('[for="size_v_MEDIUM FORMAT"]');
        if ( !$.isEmptyObject( $mediumSize ) ) {
          $mediumSize.text( translationMediumSize );
        }

        var $smallSize = $('[for="size_v_SMALL FORMAT"]');
        if ( !$.isEmptyObject( $smallSize ) ) {
          $smallSize.text( translationSmallSize );
        }

        var $workNumbered = $('.js-is-numbered');
        if ( !$.isEmptyObject( $workNumbered ) ) {
          $workNumbered.text( translationNumbered );
          $workNumbered.addClass( 'pipe-prefix' );
        } else {
          $workNumbered.removeClass( 'pipe-prefix' );
        }
        var $workSigned = $('.js-is-signed');
        if ( !$.isEmptyObject( $workSigned ) ) {
          $workSigned.text( translationSinged );
          $workSigned.addClass( 'pipe-prefix' );
        } else {
          $workSigned.removeClass( 'pipe-prefix' );
        }
      },
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.